import { Button, Col, DatePicker, Form, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { debitAccountNumber, downloadRecipt } from "../services/ApiService";
import * as XLSX from "xlsx";

const { RangePicker } = DatePicker;
function formatDate(date) {
  if (date) {
    var dateParts = date.split("-");
    var formattedDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    return formattedDate;
  }
  return "";
}
function BankFileReport() {
  const [accNumberArray, setAccNumberArray] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [paymentDate, setPaymentDate] = useState();
  const [accNumber, setAccNumber] = useState();
  const [data, setData] = React.useState([]);
  const getFormattedDate = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();

    return `${day}${month}${year}_PC_Exp_Reimbursement`;
  };

  const formattedDate = getFormattedDate();

  useEffect(() => {
    debitAccountNumber().then((res) => {
      if (res.responseCode === 200) {
        setAccNumberArray(res.data);
      } else {
        message.error(res.responseMessage);
      }
    });
  }, []);
  const handleDateChange = (dates, dateStrings) => {
    const startDateString = formatDate(dateStrings[0]);
    const endDateString = formatDate(dateStrings[1]);

    setStartDate(startDateString);
    setEndDate(endDateString);
  };
  const downloadReceipt = () => {
    let obj = {
      startDate: startDate,
      endDate: endDate,
      paymentDate: paymentDate,
      debitAccount: accNumber,
      employeeId: sessionStorage.getItem("employeeId"),
      organization: sessionStorage.getItem("organization"),

    };
    downloadRecipt(obj).then((res) => {
      if (res.responseCode === 200) {
        if (res.data.length === 0) {
          message.info("No data to export");
          return;
        }

        const headers = Object.keys(res.data[0]); // Extracting headers dynamically from the first object
        const ws = XLSX.utils.json_to_sheet(res.data, { header: headers });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, `${formattedDate}.xls`, { bookType: "xls" }); // Specify bookType as 'xls'
      } else {
        message.error(res.responseMessage);
      }
    });
  };

  return (
    <div style={{ marginTop: "0.6rem", marginLeft: "2rem" }}>
      <Row gutter={15}>
        <Col lg={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
              Select Date
            </label>

            <RangePicker
              allowClear={false}
              onChange={handleDateChange}
              format="DD-MM-YYYY"
            />
          </div>
        </Col>
        <Col lg={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
              Debit Account Number
            </label>

            <Select
              onChange={(e) => setAccNumber(e)}
              // style={{ width: "12vw" }}
              placeholder="Select Account Number"
            >
              {accNumberArray?.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col lg={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
              Payment Date
            </label>

            <DatePicker
              showToday={false}
              allowClear={false}
              // selected={form.checkIn}
              onChange={
                (date, dateStrings) => setPaymentDate(dateStrings)
                // console.log(dateStrings)
              }
              dateFormat="dd/MM/yyyy"
              // minDate={new Date()}
              placeholderText="Select Payment Date"
            />
          </div>
        </Col>
        <Col lg={6}>
          <Button
            onClick={() => {
              downloadReceipt();
            }}
            style={{
              backgroundColor: "#534ECC",
              color: "white",
              marginTop: "1.7rem",
            }}
          >
            <DownloadOutlined />
            Download Report
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default BankFileReport;
