import {
  Button,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../datepicker.css";
import "../../carrental.css";
import { carRental } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

import {
  ExpenseCancelRequest,
  PreviewFile,
  cancelRequest,
  clearRequest,
  costenDriveget,
  currencyList,
  exchangeRate,
  expenseClear,
  expensetransport,
  getExpenseTransport,
  getTransport,
  ocr,
  postTransport,
  transport,
} from "../../../services/ApiService";
import { runes } from "runes2";
import pic from "../../CostenDemo/_Sticker.png";

import { useNavigate } from "react-router-dom";
import { FileTextOutlined, UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;
function formatDate(date) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
}
function PullCarRental({ triggerParentEffect }) {
  const [disabledExchange, setDisabledExchange] = useState(true);
  const [openpreview, setOpenpreview] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [billdate, setBillDate] = useState(null);
  const [form, setform] = useState({
    estimateCost: "0",
    startDate: "",
    endDate: "",
    billCurrency: "",
    billAmount: "",
    billDate: null,
    billNumber: "",
    establishmentName: "",
    file: "",
    exchangeRate: "",
    finalAmount: "",
    billFile: "",
    billFileOriginal: "",
    expenseType: "cash",
    comment: "",
  });
  const allowedFormats = ["pdf", "jpeg", "jpg", "heic", "png"];

  const requestid = useSelector((state) => state.requestedid);
  const userType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const carRentalData = useSelector((state) => state.carRental);
  const dispatch = useDispatch();
  const [modalFile, setModalFile] = useState(null);
  const [modalFileType, setModalFileType] = useState("");
  const [previewlink, setPreviewLink] = useState();
  const [billFileName, setBillFileName] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currencyListData, setCurrencyListData] = useState([]);
  const [indexformulticosten, setIndexformulticosten] = useState();
  const [costenModal, setCostenModal] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const today = new Date();
  const handleSave = () => {
    form["startDate"] = formatDate(startDate);
    form["endDate"] = formatDate(endDate);
    form["billDate"] = formatDate(billdate);
    // for (let key in form) {
    //   if (typeof form[key] === "number" && isNaN(form[key])) {
    //     form[key] = 0;
    //   }
    // }
    for (let key in form) {
      if (form[key] === null || form[key] === undefined) {
        form[key] = "";
      }
    }

    for (const key in form) {
      if (
        form["billFile"] === "" ||
        form["billFileOriginal"] === "" ||
        form["billFile"] === null ||
        form["billFileOriginal"] === null
      ) {
        delete form["billFile"];
        delete form["billFileOriginal"];
      } else if (form["file"] === "" || form["file"] === null) {
        delete form["file"];
      }
    }
    for (const key in form) {
      if (key !== "file") {
        if (!form[key]) {
          message.error("All Fields are required");
          return;
        }
      }
    }
    const missingBothKeys = !form.file && !form.billFile;
    if (missingBothKeys || !form.comment) {
      message.error("All Fields are required this");
    } else {
      const formData = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        formData.append(`${key}`, value);
      });
      formData.append("requestId", requestid);
      formData.append("employeeId", sessionStorage.getItem("employeeId"));
      expensetransport(formData, "carRental").then((res) => {
        if (res.responseCode === 200) {
          triggerParentEffect(formData);
          message.success("Car Rental Save Successfully");
        } else {
          message.error(res.responseMessage);
        }
      });
    }
    // console.log(formData);
    // let obj = {
    //   requestId: requestid,
    //   employeeId: sessionStorage.getItem("employeeId"),
    //   transportType: "carRental",
    //   estimateCost: value.estimateCostCarRental,
    //   comment: value.comment1,
    //   startDate: formatStartDate,
    //   endDate: formatEndDate,
    // };
    // if (
    //   Object.values(obj).some(
    //     (value) =>
    //       value !== null && value !== "" && value !== undefined && !isNaN(value)
    //   )
    // ) {
    //   postTransport("carRental", obj).then((res) => {
    //     if (res.responseCode === 200) {
    //       message.success("Car Rental Data Saved Succesfully");
    //     } else {
    //       message.error(res.responseMessage);
    //     }
    //   });
    //   let reduxobj = {
    //     transportType: "carRental",
    //     estimateCost: value.estimateCostCarRental,
    //     comment: value.comment1,
    //     startDate: formatStartDate,
    //     endDate: formatEndDate,
    //   };
    //   dispatch(carRental(reduxobj));
    // } else {
    //   message.error("Add full data for car rental to save");
    // }

    // console.log(form);
  };
  const handleDownloadClick = (backendLink) => {
    window.open(backendLink, "_blank");
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const previewStyle = {
    maxWidth: "100%",
    maxHeight: "400px",
    margin: "20px 0",
  };

  const handleFinalAmountCalculation = () => {
    const exchangeRate = parseFloat(form.exchangeRate);
    const billAmount = parseFloat(form.billAmount);

    if (!isNaN(exchangeRate) && !isNaN(billAmount)) {
      const finalAmount = exchangeRate * billAmount;
      setform((prevForm) => ({
        ...prevForm,
        finalAmount: finalAmount.toFixed(2), // Adjust the decimal places as needed
      }));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setform({ ...form, [name]: value });
  };
  const handleBillDateChange = (date) => {
    setBillDate(date);
  };
  const handleBillCurrency = (value) => {
    setform({ ...form, ["billCurrency"]: value });

    if (value === "Other") {
      setDisabledExchange(false);
    } else {
      setDisabledExchange(true);
      exchangeRate(value, sessionStorage.getItem("currency")).then((res) => {
        if (res.responseCode === 200) {
          setform({
            ...form,
            ["exchangeRate"]: res.exchangeRate,
            ["billCurrency"]: value,
          });
        } else {
          message.error(res.responseMessage);
        }
      });
    }
  };
  useEffect(() => {
    handleFinalAmountCalculation();
  }, [form.exchangeRate, form.billAmount]);
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop().toLowerCase();

      if (allowedFormats.includes(fileType)) {
        const ocrform = new FormData();

        ocrform.append("file", selectedFile);
        // if (
        //   form["billAmount"] !== "" &&
        //   form["billDate"] !== null &&
        //   form["billNumber"] !== "" &&
        //   form["establishmentName"] !== ""
        // ) {
        //   console.log(
        //     onewayform["billAmountoneway"],
        //     onewayform["billDateoneway"],
        //     onewayform["billNumberoneway"],
        //     onewayform["establishmentNameoneway"]
        //   );
        // } else {
        setSpinner(true);
        ocr(ocrform)
          .then((res) => {
            if (res.responseCode === 200) {
              setSpinner(false);
              form["billAmount"] = res.fileData.billAmount;
              form["billDate"] =
                res.fileData.billDate !== null
                  ? new Date(res.fileData?.billDate)
                  : null;
              form["billNumber"] =
                res.fileData.billNumber !== null ? res.fileData.billNumber : "";
              form["establishmentName"] = res.fileData.establishmentName;
              setBillDate(
                res.fileData.billDate !== null
                  ? new Date(res.fileData?.billDate)
                  : null
              );
            } else {
              setSpinner(false);
              message.error(res.responseMessage);
            }
          })
          .catch((error) => {
            setSpinner(false);
            message.error(error);
          });
        // }

        form["billFile"] = "";
        form["billFileOriginal"] = "";

        form["file"] = selectedFile;
        setModalFile(selectedFile);
        setModalFileType(fileType);
      } else {
        message.info("Accepted files formats : JPEG, JPG, PDF, HEIC, PNG.");

        // alert(
        //   "Invalid file format. Please upload a PDF, JPEG, JPG, or HEIC file."
        // );
      }
    }
  };
  useEffect(() => {
    currencyList().then((res) => {
      if (res.responseCode === 200) {
        const arr = res.data;
        arr.push("Other");
        setCurrencyListData(arr);
      } else {
        message.error(res.responseMessage);
      }
    });
    getExpenseTransport(requestid).then((res) => {
      if (res.responseCode === 200) {
        if (res.data.length > 0) {
          let getCarRentalData = res.data?.filter(
            (item) => item.transportType === "carRental"
          );
          if (getCarRentalData.length > 0) {
            setStartDate(
              new Date(
                getCarRentalData[0].startDate !== null
                  ? new Date(getCarRentalData[0]?.startDate)
                  : null
              )
            );
            setEndDate(
              getCarRentalData[0].endDate !== null
                ? new Date(getCarRentalData[0]?.endDate)
                : null
            );
            setBillDate(
              getCarRentalData[0].billDate !== null
                ? new Date(getCarRentalData[0]?.billDate)
                : null
            );
            const initialFormDataArray = {
              startDate:
                getCarRentalData[0].startDate !== null
                  ? new Date(getCarRentalData[0]?.startDate)
                  : null,
              endDate:
                getCarRentalData[0].endDate !== null
                  ? new Date(getCarRentalData[0]?.endDate)
                  : null,
              billAmount: getCarRentalData[0].billAmount || "",
              establishmentName: getCarRentalData[0].establishmentName,
              billCurrency: getCarRentalData[0].billCurrency,
              billNumber: getCarRentalData[0].billNumber,
              exchangeRate: getCarRentalData[0].exchangeRate,
              expenseType: getCarRentalData[0].expenseType,
              billFile: getCarRentalData[0].billFile,
              billFileOriginal: getCarRentalData[0].billFileOriginalName,
              finalAmount: getCarRentalData[0].finalAmount,
              billDate:
                getCarRentalData[0].billDate !== null
                  ? new Date(getCarRentalData[0]?.billDate)
                  : null,
              estimateCost: getCarRentalData[0].estimateCost,
            };
            setform(initialFormDataArray);
          }
        }
      } else {
        // message.error(res.responseMessage);
      }
    });
    // if (Object.keys(carRentalData).length > 0) {
    //   form.setFieldsValue({
    //     estimateCostCarRental: carRentalData.estimateCost,
    //     comment1: carRentalData.comment,
    //   });
    //   setStartDate(new Date(carRentalData.startDate));
    //   setEndDate(new Date(carRentalData.endDate));
    // }
  }, []);
  const onCancel = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    setOpen(false);
    let body = {
      requestId: requestid,
    };
    if (requestid) {
      ExpenseCancelRequest(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Canceled the Request Successfully");
          navigate("/dashboard-expense");
          // if (userType == "1") {
          //   navigate("/dashboard-m");
          // } else {
          //   navigate("/dashboard");
          // }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      navigate("/dashboard-expense");

      message.error("Data is not Saved Yet");
    }
  };
  const onClear = () => {
    if (requestid) {
      setStartDate(null);
      setEndDate(null);
      setBillDate(null);
      setform({
        estimateCost: "0",
        startDate: "",
        endDate: "",
        billCurrency: "",
        billAmount: "",
        billDate: null,
        billNumber: "",
        establishmentName: "",
        file: "",
        exchangeRate: "",
        finalAmount: "",
        billFile: "",
        billFileOriginal: "",
        expenseType: "cash",
        comment: "",
      });
      let body = {
        requestId: requestid,
        requestType: "transport",
        transportType: "carRental",
      };
      expenseClear(body).then((res) => {
        if (res.responseCode === 200) {
          triggerParentEffect(body);
          message.success("Car Rental Data Cleared Successfully");
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.error("Please Save Travel Overview Data");
    }
  };
  function getFileExtension(url) {
    const path = url.substring(url.lastIndexOf("/") + 1);
    const extension = path.substring(path.lastIndexOf(".") + 1);
    return extension;
  }
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);

  const handleCheckboxChange = (itemId) => {
    // When a checkbox is clicked, update the selectedItemId state
    setSelectedItemId(itemId);

    // Find the details of the selected item and update the state
    const selectedItem = data.find((item) => item.id === itemId);
    setSelectedItemDetails(selectedItem);
  };

  const handleUpload = () => {
    if (selectedItemDetails) {
      const ocrform = new FormData();

      ocrform.append("file", selectedItemDetails.previewLink);
      setCostenModal(false);
      setSpinner(true);

      form["billFileOriginal"] = selectedItemDetails.originalFileName;
      form["billFile"] = selectedItemDetails.fileName;
      form["file"] = "";

      ocr(ocrform)
        .then((res) => {
          if (res.responseCode === 200) {
            setSpinner(false);
            form["billAmount"] = res.fileData.billAmount;
            form["billDate"] =
              res.fileData.billDate !== null
                ? new Date(res.fileData?.billDate)
                : null;
            form["billNumber"] =
              res?.fileData?.billNumber === null
                ? ""
                : res?.fileData?.billNumber;
            form["establishmentName"] = res.fileData.establishmentName;
            setBillDate(
              res.fileData.billDate !== null
                ? new Date(res.fileData?.billDate)
                : null
            );
          } else {
            setSpinner(false);
            message.error(res.responseMessage);
          }
        })
        .catch((error) => {
          setSpinner(false);
          message.error(error);
        });
    } else {
      message.info("Select before clicking Upload");
    }
  };
  const [data, setData] = useState([]);

  const handleCostenDriveModal = (index) => {
    setIndexformulticosten(index);
    setCostenModal(true);
    const headers = {
      organization: sessionStorage.getItem("organization"),
      employeeId: sessionStorage.getItem("employeeId"),
    };
    costenDriveget(headers).then((responseData) => {
      if (responseData.responseCode === 200) {
        setData(responseData.data);
      } else {
        message.error(responseData.responseMessage);
      }
    });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    // Filter data based on search query whenever searchQuery changes
    const filtered = data.filter((item) =>
      item.originalFileName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchQuery]);
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "flex-start",
        borderRadius: "5px",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "40vh",
          // overflowY: "auto",
          // overflowX: "hidden",
        }}
        className="scrollable-container"
      >
        <Spin spinning={spinner} tip="Fetching Data ..." size="large">
          <div className="scroll-container-pull">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <label
                        style={{
                          fontWeight: "600",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        onClick={handleCostenDriveModal}
                      >
                        Upload from Costen Drive
                      </label>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <label
                        style={{
                          fontWeight: "600",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <input
                          type="file"
                          accept=".pdf,.jpeg,.jpg,.heic,.png"
                          style={{ display: "none" }}
                          onChange={onFileChange}
                        />
                        Upload from PC
                      </label>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button style={{
                      backgroundColor: "#3052D0",
                      color: "white",
                      margin: "0.7rem 0 0 1.5rem",
                      fontWeight: "600",
                    }} >
                  <Space>
                    <label>
                      <PlusOutlined className="mr-1 ml-0" />
                      Upload Receipt
                    </label>
                  </Space>
                </Button>
              </Dropdown>
              <>
                {form.file ? (
                  <div
                    onClick={() => {
                      setModalFile(form.file);
                      setModalFileType(
                        form?.file?.name.split(".").pop().toLowerCase()
                      );
                      setOpenpreview(true);
                    }}
                    style={{
                      marginTop: "1.2rem",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                  >
                    <FileTextOutlined style={{ fontSize: "15px" }} />
                    <span> {form.file?.name}</span>
                  </div>
                ) : form.billFile ? (
                  <div
                    onClick={() => {
                      PreviewFile(form.billFile).then((res) => {
                        setPreviewLink(res.url);
                        setBillFileName(form.billFileOriginal);
                        setModalFileType(getFileExtension(res.url));
                        setModalFile(null);
                        setOpenpreview(true);
                      });
                    }}
                    style={{
                      marginTop: "1.2rem",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                  >
                    <FileTextOutlined style={{ fontSize: "15px" }} />
                    <span> {form.billFileOriginal}</span>
                  </div>
                ) : (
                  <></>
                )}
                {/* {onewayform.file ? (
                    <div
                      onClick={() => {
                        setModalFile(onewayform.file);
                        setModalFileType(
                          onewayform?.file?.name.split(".").pop().toLowerCase()
                        );
                        setOpenpreview(true);
                      }}
                      style={{
                        marginTop: "1.5rem",
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                        marginLeft: "0.1rem",
                      }}
                    >
                      <FileTextOutlined style={{ fontSize: "15px" }} />
                      <span> {onewayform.file?.name}</span>
                    </div>
                  ) : onewayform.billFileoneway ? (
                    <div
                      onClick={() => {
                        PreviewFile(onewayform.billFileoneway).then((res) => {
                          // handleDownloadClick(res.fileUrl);
                          setPreviewLink(res.url);
                          setBillFileName(onewayform.billFileOriginaloneway);
                          setModalFileType(getFileExtension(res.url));
                          setOpenpreview(true);
                        });
                      }}
                      style={{
                        marginTop: "1.5rem",
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                        marginLeft: "0.1rem",
                      }}
                    >
                      <FileTextOutlined style={{ fontSize: "15px" }} />
                      <span> {onewayform.billFileOriginaloneway}</span>
                    </div>
                  ) : (
                    <></>
                  )} */}
              </>
            </div>
            <form
              // onSubmit={handleSubmit}
              style={{
                // display: "flex",
                // flexWrap: "nowrap",
                padding: "20px",
                // width: "40vw",
              }}
            >
              <div className="panel-content grid grid-cols-4 gap-1">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                    Start Date
                  </label>

                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="dd/MM/yyyy"
                    // required
                    minDate={today} // Disable dates before today
                    className="form-control w-52 md:w-48"
                    placeholderText="Select Start Date"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                    End Date
                  </label>

                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="dd/MM/yyyy"
                    // required
                    minDate={startDate || today} // Disable dates before start date or today
                    className="form-control w-52 md:w-48"
                    placeholderText="Select End Date"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Estimate Cost</label>
                  <input
                    disabled
                    // style={{ width: "9vw" }}
                    className="inputclass w-64 md:w-48"
                    name="estimateCost"
                    type="number"
                    value={form.estimateCost}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Amount"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>
                    Establishment Name
                  </label>
                  <input
                    className="inputclass w-64 md:w-48"
                    name="establishmentName"
                    type="text"
                    value={form.establishmentName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Establishment Name"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Bill Date</label>
                  <DatePicker
                    selected={billdate}
                    onChange={handleBillDateChange}
                    className="form-control w-52 md:w-48"
                    dateFormat="dd/MM/yyyy"
                    // className="form-control"
                    placeholderText="Select Bill Date"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Bill Currency</label>
                  <Select
                    value={form.billCurrency}
                    listItemHeight={10}
                    listHeight={200}
                    showSearch
                    onChange={(value) => handleBillCurrency(value)}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    placeholder="Select Bill Currency"
                    // style={{ width: 200 }}
                    className="w-64 md:w-52"
                    virtual={true}
                  >
                    {currencyListData?.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Bill Amount</label>
                  <input
                    className="inputclass w-64 md:w-48"
                    name="billAmount"
                    type="number"
                    value={form.billAmount}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Bill Amount"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Bill Number</label>
                  <input
                    className="inputclass w-64 md:w-48"
                    name="billNumber"
                    type="text"
                    value={form.billNumber}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Bill Number"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>
                    Requested Currency
                  </label>
                  <input
                    className="inputclass w-64 md:w-48"
                    name="requestedCurrency"
                    type="text"
                    value={sessionStorage.getItem("currency")}
                    disabled
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Exchange Rate</label>
                  <input
                    // disabled={disabledExchange}
                    className="inputclass w-64 md:w-48"
                    name="exchangeRate"
                    type="text"
                    value={form.exchangeRate}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Exchange Rate"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Final Amount</label>
                  <input
                    className="inputclass w-64 md:w-48"
                    name="finalAmount"
                    type="text"
                    value={form.finalAmount}
                    disabled

                    // placeholder="Enter Exchange Rate"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                    marginRight: "0.4rem",
                  }}
                >
                  <label style={{ fontWeight: "600" }}>Expense Type</label>
                  <Select
                    value={form.expenseType}
                    onChange={(e) => {
                      setform({ ...form, ["expenseType"]: e });
                      // onewayform["expenseTypeoneway"] = e;
                    }}
                    // style={{ width: "13vw" }}
                    className="w-64 md:w-52"
                    placeholder="Select Expense Type"
                  >
                    <Select.Option key="cash" value="cash">
                      Cash
                    </Select.Option>
                    <Select.Option key="corporateCard" value="corporateCard">
                      Corporate Card
                    </Select.Option>
                    <Select.Option
                      key="personalExpense"
                      value="personalExpense"
                    >
                      Personal Expense
                    </Select.Option>
                  </Select>
                </div>
                {/* 
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    gap: "0.2rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label
                      style={{
                        fontWeight: "600",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type="file"
                        accept=".pdf, .jpeg, .jpg, .heic, .png"
                        onChange={onFileChange}
                        style={{ display: "none" }}
                      />
                      Upload
                      <UploadOutlined
                        style={{
                          marginLeft: "1rem",
                          marginTop: "0.4rem",
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "#E93B77",
                        }}
                      />
                    </label>

                    {form.file ? (
                      <div
                        onClick={() => {
                          setModalFile(form.file);
                          setModalFileType(
                            form?.file?.name.split(".").pop().toLowerCase()
                          );
                          setOpenpreview(true);
                        }}
                        style={{
                          marginTop: "1.2rem",
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                        }}
                      >
                        <FileTextOutlined style={{ fontSize: "15px" }} />
                        <span> {form.file?.name}</span>
                      </div>
                    ) : form.billFile ? (
                      <div
                        onClick={() => {
                          PreviewFile(form.billFile).then((res) => {
                            setPreviewLink(res.url);
                            setBillFileName(form.billFileOriginal);
                            setModalFileType(getFileExtension(res.url));
                            setOpenpreview(true);
                          });
                        }}
                        style={{
                          marginTop: "1.2rem",
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                        }}
                      >
                        <FileTextOutlined style={{ fontSize: "15px" }} />
                        <span> {form.billFileOriginal}</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div> */}
              </div>
            </form>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginBottom: "0.5rem",
            }}
          >
            <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
              Comment
            </label>
            <TextArea
              // count={{
              //   show: true,
              //   max: 1000,
              //   strategy: (txt) => runes(txt).length,
              //   exceedFormatter: (txt, { max }) =>
              //     runes(txt).slice(0, max).join(""),
              // }}
              value={form.comment}
              onChange={(e) => {
                form["comment"] = e.target.value;
              }}
              className="custom-radio-group"
              autoSize={{
                minRows: 2,
                maxRows: 3,
              }}
              placeholder="Please describe the taxi cost details here"
            />
          </div>
        </Spin>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "1.5rem" }}>
        <Popconfirm
          title="Please Validate Data Before Saving"
          okText="Save"
          cancelText="Cancel"
          // description="Open Popconfirm with Promise"
          onConfirm={handleSave}
        >
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "#3052D0",
              border: "none",
              color: "white",
            }}
            // onClick={handleSave}
          >
            Save
          </Button>
        </Popconfirm>
        <Button
          style={{
            width: "8.5rem",
            backgroundColor: "red",
            border: "none",
            color: "white",
          }}
          onClick={onClear}
        >
          Clear
        </Button>
        <Popconfirm
          title="Are you sure, want to Cancel the whole request"
          okText="Yes"
          cancelText="No"
          // description="Open Popconfirm with Promise"
          onConfirm={onSubmit}
          // onCancel={() => setOpen(false)}
        >
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "transparent",
              border: "1px solid red",
              color: "red",
            }}
            // onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </Popconfirm>
      </div>
      {/* <Modal
        open={open}
        title="Are you sure, want to Cancel the whole request"
        onCancel={() => setOpen()}
        footer={[
          <Button key="submit" type="primary" onClick={onSubmit}>
            Yes
          </Button>,
          <Button onClick={() => setOpen(false)}>No</Button>,
        ]}
      ></Modal> */}
      {openpreview ? (
        <Modal
          title={modalFile ? modalFile.name : billFileName}
          centered={false}
          style={{ top: "0px" }}
          open={openpreview}
          // title="Are you sure, want to Cancel the whole request"
          onCancel={() => setOpenpreview(false)}
          footer={false}
          width={800}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {modalFileType === "pdf" ? (
              <iframe
                src={modalFile ? URL.createObjectURL(modalFile) : previewlink}
                title="PDF Preview"
                width="100%"
                height="450px"
              />
            ) : (
              <img
                src={modalFile ? URL.createObjectURL(modalFile) : previewlink}
                alt="Preview"
                style={previewStyle}
              />
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
      <Modal
        // title={modalFile ? modalFile.name : billFileName}
        centered={false}
        style={{ top: "0px" }}
        open={costenModal}
        // title="Are you sure, want to Cancel the whole request"
        onCancel={() => {
          setCostenModal(false);
          setSelectedItemId(null);
          setSelectedItemDetails(null);
        }}
        footer={
          <Button
            className="bg-[#3052D0] text-white ml-5 mt-0 font-medium"
            onClick={handleUpload}
          >
            Upload
          </Button>
        }
        width={1000}
      >
        <div>
          {/* Search input */}
          <input
            className="inputclass"
            type="text"
            placeholder="Search by filename..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ padding: "5px", borderRadius: "15px", height: "5vh" }}
          />

          {/* Display filtered data */}
          <div className="grid grid-cols-4 h-96 md:h-96 scroll-m-3 scrollable-container mt-4">
            {filteredData.map((item, index) => (
              <div key={index}>
                <div
                  className="bg-slate-200 m-0 mt-2 ml-0 mr-0 p-8 w-40 h-38"
                  style={{ cursor: "pointer" }}
                >
                  {getFileExtension(item.previewLink) === "pdf" ? (
                    <iframe
                      src={item.previewLink}
                      title="PDF Preview"
                      className="w-40 h-36 ml-1 rounded-sm"
                      style={{ overflow: "hidden" }}
                    />
                  ) : (
                    <img
                      src={item.previewLink}
                      alt="Preview"
                      className="w-40 h-36 ml-1 rounded-sm"
                    />
                  )}
                </div>
                <div className="mb-4 bottom-0 rounded-lg flex w-56 justify-between bg-white">
                  <div className="flex shadow-sm">
                    <img src={pic} alt="img" className="h-10 w-10 shadow-sm" />
                    <h1 className="text-start text-sm ml-2 text-black font-normal">
                      {item.originalFileName}
                    </h1>
                  </div>
                  <input
                    type="checkbox"
                    id={`checkbox-${item.id}`}
                    value={item.id}
                    checked={selectedItemId === item.id}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PullCarRental;
