import React, { useState } from "react";
import { Button, DatePicker, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { auditRecipt } from "../services/ApiService";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;

function formatDate(date) {
  if (date) {
    var dateParts = date.split("-");
    var formattedDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    return formattedDate;
  }
  return "";
}
function AuditReports() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const getFormattedDate = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();

    return `${day}${month}${year}_Audit_Report`;
  };

  const formattedDate = getFormattedDate();
  const handleDateChange = (dates, dateStrings) => {
    const startDateString = formatDate(dateStrings[0]);
    const endDateString = formatDate(dateStrings[1]);

    setStartDate(startDateString);
    setEndDate(endDateString);
  };
  const downloadReceipt = () => {
    let obj = {
      startDate: startDate,
      endDate: endDate,
      employeeId: sessionStorage.getItem("employeeId"),
      organization: sessionStorage.getItem("organization"),

    };
    auditRecipt(obj).then((res) => {
      if (res.responseCode === 200) {
        if (res.data.length === 0) {
          message.info("No data to export");
          return;
        }

        const headers = Object.keys(res.data[0]);
        const ws = XLSX.utils.json_to_sheet(res.data, { header: headers });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
        XLSX.writeFile(wb, `${formattedDate}.xls`, { bookType: "xls" });
      } else {
        message.error(res.responseMessage);
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "2rem",
        marginTop: "0.6rem",
        marginLeft: "2rem",
        height:'50vh',
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
          Select Date
        </label>
        <RangePicker
          allowClear={false}
          onChange={handleDateChange}
          format="DD-MM-YYYY"
        />
      </div>
      <Button
        style={{
          backgroundColor: "#534ECC",
          color: "white",
          marginTop: "1.6rem",
        }}
        onClick={() => {
          downloadReceipt();
        }}
      >
        <DownloadOutlined />
        Download Report
      </Button>
    </div>
  );
}

export default AuditReports;
